import Vue from 'vue'
import axios from 'axios'
//import axiosSMS from 'axios'
import store from '@/store'

let endpoint = '';

//endpoint = process.env.BackEndPoint;

let irParaAws = true;

if (!irParaAws) {
    endpoint = 'https://localhost:5001/api';
} else {
    endpoint = 'http://15.228.53.87/api'; // chamada direta ao beanstalk funciona


    //endpoint = 'https://api-doctoron.com.br/api'; // por http funciona
}

axios.defaults.baseURL = endpoint; 
//axiosSMS.defaults.baseURL = 'http://www.geforsoft.com.br/cgi-bin/sms/cgi_sms.exe'

//------------------------------------------------------------ Chamar a tela de Login quando expira Token
const Success = resp => resp;
const Error = err => {
    if (err.response.status===401) {
        window.location = '/';
    } else {
        return Promise.reject(err);
    }
}

axios.interceptors.response.use(Success, Error);
//----------------------------------------------------------- Adicionar Header com Token de Autenticação
axios.interceptors.request.use(
    function (config) {
        config.headers.InstanciaBancoDeDados = store.state.global.instanciaBancoDeDados;
        if (store.state.global.usuarioLogadoAutenticacao.accessToken) {
          config.headers.Authorization = `Bearer ${store.state.global.usuarioLogadoAutenticacao.accessToken}`;
        }

        return config;
    }, 
    function(err) {
        return Promise.reject(err);
    }
);
//-----------------------------------------------------------

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
        //Vue.prototype.$httpSMS = axiosSMS
    }
})

export default {}